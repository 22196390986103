import { Trans } from 'react-i18next';
// @mui
import { Stack, Button, Typography, StackProps } from '@mui/material';
// locales
import { useLocales } from '../../locales';
// hooks
import useResponsive from '../../hooks/useResponsive';
// utils
import { getSocialIcon } from '../../utils/getIcon';
// redux
import { SocialNetworkItem } from '../../redux/coreApi';
// constants
import { EXPIRED_DAY } from '../../constants';
// config
import { HEADER } from '../../config-global';

// ----------------------------------------------------------------------

interface Props extends StackProps {
  socialNetworkLinks: SocialNetworkItem[];
  userId?: string;
}

export default function ExpiryAlert({ socialNetworkLinks, sx, userId, ...other }: Props) {
  return (
    <Stack
      sx={{
        mb: 3,
        zIndex: 9,
        position: 'sticky',
        mx: { lg: -2 },
        top: {
          xs: `${HEADER.H_MOBILE}px`,
          lg: `${HEADER.H_DASHBOARD_DESKTOP_OFFSET}px`,
        },
        ...sx,
      }}
      {...other}
    >
      {socialNetworkLinks.map((social) =>
        Number(social.tokenExpiry) < EXPIRED_DAY ? (
          <ExpiryAlertItem key={social.name} social={social} userId={userId} />
        ) : null
      )}
    </Stack>
  );
}

// ----------------------------------------------------------------------

interface Props extends StackProps {
  socialNetworkLinks: SocialNetworkItem[];
}

type ExpiryAlertItemProps = {
  social: SocialNetworkItem;
  userId?: string;
};

function ExpiryAlertItem({ social, userId }: ExpiryAlertItemProps) {
  const { translate } = useLocales();

  const isMdUp = useResponsive('up', 'sm');

  const handleClickReconnect = () => {
    // Old code for reconnection, scopes are different than the one used in signup process
    // if (social.name === 'instagram') {
    //   window.open(
    //     `https://www.facebook.com/v19.0/dialog/oauth?response_type=code,granted_scopes&display=popup&client_id=${process.env.REACT_APP_FACEBOOK_APP_ID}&redirect_uri=${process.env.REACT_APP_FBC_URL}&scope=email,pages_show_list,instagram_basic,instagram_manage_insights,pages_read_engagement,public_profile,read_insights,pages_read_user_content,pages_manage_metadata,pages_manage_ads,business_management&state=${userId}&reconnect=true`,
    //     `_self`
    //   );
    // }
    if (social.name === 'instagram') {
      window.open(
        `https://www.facebook.com/v19.0/dialog/oauth?response_type=code,granted_scopes&display=popup&client_id=${process.env.REACT_APP_FACEBOOK_APP_ID}&redirect_uri=${process.env.REACT_APP_FBC_URL}&scope=email,public_profile,pages_manage_metadata,instagram_basic,instagram_manage_insights,pages_show_list,business_management&state=${userId},reconnect=true`,
        `_self`
      );
    }

    if (social.name === 'youtube') {
      window.open(
        `https://accounts.google.com/o/oauth2/v2/auth?scope=https://www.googleapis.com/auth/youtube.readonly https://www.googleapis.com/auth/yt-analytics.readonly&access_type=offline&include_granted_scopes=true&state=${userId}&redirect_uri=${process.env.REACT_APP_YTC_URL}&response_type=code&prompt=consent&client_id=${process.env.REACT_APP_YOUTUBE_APP_ID}`,
        `_self`
      );
    }

    if (social.name === 'tiktok') {
      window.open(
        `https://www.tiktok.com/v2/auth/authorize/?client_key=${process.env.REACT_APP_TIKTOK_CLIENT_KEY}&scope=user.info.basic,user.info.profile,user.info.stats,video.list&response_type=code&redirect_uri=${process.env.REACT_APP_TTC_URL}&state=${userId}`,
        `_self`
      );
    }
  };

  const expiredToken = Number(social.tokenExpiry) <= 0;

  return (
    <Stack
      key={social.name}
      spacing={2}
      direction={{ xs: 'column', sm: 'row' }}
      alignItems="center"
      justifyContent="center"
      sx={{
        py: 1.5,
        bgcolor: 'error.main',
      }}
    >
      {isMdUp && getSocialIcon(social.name)}

      <Typography variant="h6" sx={{ color: 'common.white', textAlign: 'center' }}>
        {expiredToken ? (
          <Trans i18nKey="connection_expired" values={{ social: social.name }} />
        ) : (
          <Trans
            i18nKey="connection_will_expired"
            values={{ social: social.name, dayNumber: social.tokenExpiry }}
          />
        )}
      </Typography>

      <Stack spacing={2} direction="row" alignItems="center" justifyContent="center">
        {!isMdUp && getSocialIcon(social.name)}

        <Button color="warning" variant="contained" size="small" onClick={handleClickReconnect}>
          {translate('reconnect_my_account')}
        </Button>
      </Stack>
    </Stack>
  );
}
